import React from 'react'
import LogArtboard from '../../assets/images/LogArtboard 56.svg'

export default function Buttons() {
  return (
    <div className="mb-5">
     <div className="mx-auto mt-2 w-[80%] lg:w-[300px]">
    
        <button href="https://app.uniswap.org/swap?outputCurrency="  className="animate-bounce mt-[19px] rounded-[58px] bg-primary text-white f-f-sm text-tiny text-center font-  text-white  text-center   cursor-pointer mx-auto w-[100%] py-[12px] ">
    <a href="https://app.uniswap.org/swap?outputCurrency=">
        Buy $PuppyLove
    </a>
        </button>
      
      <div className="  mt-5 ">

    
            <button href="https://drive.google.com/file/d/16e3M-ORNDLKlRyAVl30xZU-QTGkHKhXA/view"  className=" w-[100%] font-[600] w-[100%]  rounded-[58px] bg-white text-primary-dark f-f-sm text-tiny text-center py-[12px] mb-2">
      <a href="https://drive.google.com/file/d/16e3M-ORNDLKlRyAVl30xZU-QTGkHKhXA/view" >
             White Paper
    </a>
            </button>
    
   
 
    </div>
        </div>   
  
  </div>
  )
}
